const FILTER_BY_NAME_AND_TYPE = (name, type) => {
  return (item) => {
    return item.type === type && item.name === name;
  };
};

const FILTER_UNIQ = (value, index, self) => {
  return self.indexOf(value) === index;
};

const FILTER_VISIBLE_ELEMENTS = (el) => {
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
};

export {
  FILTER_VISIBLE_ELEMENTS,
  FILTER_BY_NAME_AND_TYPE,
  FILTER_UNIQ
};
